import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import logo from '../../images/webLogo.svg';
import { Link } from 'gatsby';

const NavLink = styled(Link)`
  color: #fff;
  font-size: 1rem;
  font-weight: ${props => props.fontWeight || 'normal'};
  line-height: 1;

  margin: 0 0.75rem 0 0;
  padding: 0.25rem;
  text-decoration: none;
  &.current-page {
    border-bottom: 2px solid #222;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

const Header = () => (
  <header
    className="headnav"
    css={css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      /* flex: 3 1 auto;
      align-items: center; */
      color: #fff;
      background: black;
      border-bottom: 2px solid #cab1b6;
      /* height: 5vh; */
      width: 100%;
      /* padding: 0.5rem calc((100vw - 550px - 0.5rem) / 2); */
    `}
  >
    {/* <div className="logo"> */}
    <h1 style={{ margin: 0 }}>
      <NavLink className="naviLogo" to="/" fontWeight="bold">
        <img
          style={{ width: '100px', padding: '10px' }}
          src={logo}
          alt="greenlayk logo vector graphic"
        />
      </NavLink>
    </h1>
    <nav
      css={css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 0;
        padding-right: 1.5rem;
        width: 100%;
        /* height: 5vh; */
        background: #000;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-basis: content;
          justify-content: space-between;
        `}
      ></div>

      <NavLink className="navi" to="/art/" activeClassName="current-page">
        Art
      </NavLink>
      <NavLink className="navi" to="/code/" activeClassName="current-page">
        Code
      </NavLink>
      <NavLink className="navi" to="/about/" activeClassName="current-page">
        About
      </NavLink>
      {/* </div> */}
    </nav>
  </header>
);

export default Header;
